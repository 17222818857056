<template>
  <div class="main-content">
    <b-overlay
      :show="showOverlay"
      opacity="0.60"
      rounded="sm"
      variant="white"
      no-wrap
    >
    </b-overlay>
    <h2 class="mb-4">Expense Categories</h2>
    <!-- <div class="wrapper"> -->
    <vue-good-table
      :columns="columns"
      :search-options="{
        enabled: true,
        placeholder: 'Search this table',
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        dropdownAllowAll: false,
      }"
      styleClass="tableOne vgt-table"
      :selectOptions="{
        enabled: false,
        selectionInfoClass: 'table-alert__box',
      }"
      :rows="expensecategories"
      :totalRows="accountsPaginationOpts.totalCount"
      @on-page-change="onPageChange"
      @on-per-page-change="onPageChange"
    >
      <div slot="table-actions" class="mb-3">
        <b-button variant="primary" class="btn-rounded" @click="addOrders()">
          Add Expense Category
        </b-button>
      </div>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'button'">
          <a href="">
            <i class="i-Eraser-2 text-25 text-success mr-2"></i>
            {{ props.row.button }}</a
          >
          <a href="">
            <i class="i-Close-Window text-25 text-danger"></i>
            {{ props.row.button }}</a
          >
        </span>

        <span v-else-if="props.column.field == 'action'">
          <b-dropdown
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <b-icon icon="three-dots-vertical"></b-icon>
            </template>
            <b-dropdown-item @click="navigateToEdit(props.row)">Edit</b-dropdown-item>
            <b-dropdown-item @click="openDeleteConfirm(props.row)"
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </span>
      </template>
    </vue-good-table>
    <b-modal
      id="purchase-delete-modal"
      size="sm"
      centered
      title="Delete"
      hide-footer
    >
      <b-form>
        <p>Are you sure you want to delete?</p>
        <div class="d-flex justify-content-end">
          <b-button
            @click.prevent="$bvModal.hide('purchase-delete-modal')"
            type="button"
            >Cancel</b-button
          >
          <b-button
            @click.prevent="actionDeletePurchaseOrder()"
            type="button"
            variant="primary"
            class="ml-2"
            >Yes</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Quotations",
  },
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      pageSize: 0,
      selectedRow: "",
      showOverlay: false,
      paymentForm: {
          "id": "",
          "purchaseOrderId": "",
          "paymentDate": "",
          "referenceNumber": "",
          "amount": "",
          "note": "",
          "paymentMethod": "",
          "transCurrencyId": "",
          "transAmount": ""
      },
      selectedPurchaseOrder: ""
    };
  },
  mounted() {
    this.getExpenseCategories();
  },
  computed: {
    ...mapGetters(["expensecategories", "accountsPaginationOpts"]),
  },
  methods: {
    ...mapActions(["getExpenseCategories"]),
    getAllOrders() {
      this.showOverlay = true;
      this.getAccounts(
        "?Fields=&OrderBy=expenseBy%20asc&PageSize=15&Skip=0&SearchQuery=&description=&expenseCategoryId=&reference=&expenseById=&fromDate=&toDate="
      ).then((c) => {
        this.showOverlay = false;
      });
    },
    addOrders() {
      this.$router.push("/add-expense-category");
    },
    onPageChange(params) {
      this.showOverlay = true;
      let skip;
      if (params.currentPage > params.prevPage) {
        skip = params.currentPerPage * params.prevPage;
      } else {
        skip = params.currentPerPage / params.prevPage;
      }
      this.getExpenseCategories(`pageSize=${params.currentPerPage}&skip=${skip}`)
        .then((c) => {
          this.showOverlay = false;
        })
        .catch((err) => {
          this.showOverlay = false;
        });
    },
    onPerPageChange(params) {
      this.showOverlay = true;
      this.pageSize = params.currentPerPage;
      this.getExpenseCategories(`pageSize=${this.pageSize}`)
        .then((c) => {
          this.showOverlay = false;
        })
        .catch((err) => {
          this.showOverlay = false;
        });
    },
    openDeleteConfirm(colData) {
      this.selectedRow = colData;
      this.$bvModal.show("purchase-delete-modal");
    },
    actionDeletePurchaseOrder() {
      this.deletePurchaseOrder(this.selectedRow.id)
        .then((c) => {
          this.$bvModal.hide("purchase-delete-modal");
          this.getAllOrders();
          this.$bvToast.toast(c, {
            title: "Success",
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch((err) => {
          this.$bvModal.hide("purchase-delete-modal");
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
    addPayment() {
      this.$bvModal.show("purchase-addpayment-modal");
      this.selectedPurchaseOrder = arguments[0]
      this.paymentForm.amount = arguments[0].totalAmount
    },
    hidePayment() {
      this.$bvModal.hide("purchase-addpayment-modal");
    },
    actionAddPayment() {
      this.paymentForm.purchaseOrderId = this.selectedPurchaseOrder.id
      this.addPurchaseOrderPayment(this.paymentForm).then((c) => {
          this.$bvModal.hide("purchase-addpayment-modal");
          this.$bvToast.toast(c, {
            title: "Success",
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch((err) => {
          this.$bvModal.hide("purchase-addpayment-modal");
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
    navigateToEdit(colData) {
      this.$router.push('/edit-expense-category/'+colData.id)
    },
  },
};
</script>
<style>
.vgt-inner-wrap {
  padding: 20px !important;
}
</style>
